import React from "react"
import styled from "styled-components"

const PriceTableTitle = styled.h1`
  text-align: center;
`

const PriceTableBox = styled.div`
//   background-color: Gainsboro;
//   border: solid;
  margin: 40px auto;
  display: flex;
  flex-direction: column;
  flex-flow: row wrap;
  align-items: stretch;
  -webkit-box-align: stretch;
  width: 70vw;
`

const ProductBox = styled.div`
//   border: solid;
  margin: auto;
  padding: 40px 20px;
//   background-color: Gainsboro;
  box-sizing: border-box;
  border: 3px solid #E8E8E8;
  border-radius: 7px;
  text-align: center;
  width: 40%;
  height: 400px;

  :hover {
    transform: scale(1.02);
    box-shadow: 2px 2px 5px grey, -2px -2px 5px grey;
  }

  @media (max-width: 768px) {
    width: 80%
  }
`

const ProductHeader = styled.p`
  font-size: 30px;
  // font-weight: bold;
`
const ProductPrice = styled.p`
  font-size: 60px;
`
const ProductPriceDescription = styled.p`
  font-size: 10px;
  line-height: 100%;
`
const ProductTable = styled.table`
`

export const PriceTable = () => (
  <priceTable>
    <PriceTableTitle>Nos Solutions</PriceTableTitle>
    <div style={{textAlign: "center"}}>
      Offre de lancement!<br/>
      <p>Pour fêter notre lancement, les frais de mise en place sont à <b>399€</b> au lieu de 799€.<br/>
      Ce tarif est valable jusqu'au 31 Décembre 2021, profitez en vite!</p>
    </div>
    <PriceTableBox>
      <ProductBox>
        <ProductHeader>Classic</ProductHeader>
        <ProductPrice>49€</ProductPrice>
        <ProductPriceDescription>/mois + <span style={{fontWeight: "bold"}}>399€</span> <span style={{textDecoration: "line-through", fontSize:"10px"}}>799€</span> de mise en service<br/>Tarifs HT</ProductPriceDescription>
        {/* <ProductPriceDescription>Tarification sur devis</ProductPriceDescription> */}
        <ProductTable>
          <tr>Site semi sur-mesure</tr>
          <tr>Nom de domaine et hébergement </tr>
          <tr>Création page Google MyBusiness</tr>
          <tr>Modifications</tr>
          <tr>Support et assitance</tr>
        </ProductTable>
      </ProductBox>

      {/* <ProductBox>
        <ProductHeader>Silver</ProductHeader>
        <ProductPrice>99€</ProductPrice>
        <ProductPriceDescription>/mois + <span style={{fontWeight: "bold"}}>399€</span> <span style={{textDecoration: "line-through", fontSize:"10px"}}>799€</span> de mise en service<br/>Tarifs HT</ProductPriceDescription>
        <ProductTable>
          <tr>Offre "Classic"</tr>
          <tr>+</tr>
          <tr>Support premium</tr>
          <tr>Modifications incluses</tr>
          <tr>Optimisation SEO</tr>
        </ProductTable>
      </ProductBox> */}

      <ProductBox>
        <ProductHeader>Premium</ProductHeader>
        <ProductPrice>149€</ProductPrice>
        <ProductPriceDescription>/mois + <span style={{fontWeight: "bold"}}>399€</span> <span style={{textDecoration: "line-through", fontSize:"10px"}}>799€</span> de mise en service<br/>Tarifs HT</ProductPriceDescription>
        {/* <ProductPriceDescription>Tarification sur devis</ProductPriceDescription> */}
        <ProductTable>
          <tr>Contenu de l'offre "Classic"</tr>
          <tr>Optimisation du référencement (SEO)</tr>
          <tr>Publicité en ligne</tr>
          <tr>Coaching numérique</tr>
        </ProductTable>
      </ProductBox>
    </PriceTableBox>
  </priceTable>
)
