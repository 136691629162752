import React from "react"
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import { Hero, HeroText } from "../components/Hero"
import { LeftContainer, QuadGrid, RightContainer, TripleText } from "../components/Templates"
import { PriceTable } from "../components/PriceTable"
import { Contact } from "../components/contact-us"
import SEO from "../components/seo"

const DeveloppementWeb = ({ data }) => (
  <Layout>
    <SEO title="Développement Web" />

    <Hero height="30vh" fluid={data.hero.edges[0].node.fluid}>
      <HeroText text="Nos Offres Web"/>
    </Hero>

    <TripleText/>

    <RightContainer
      imageSrc="Saadify/undraw_smart_resize_wpn8.png"
      header="Packs web prédéfinis"
      text={[ "Nos packs web prédéfinis sont la solution idéale afin de vous proposer la réalisation de votre site webs à prix compétitifs dans des délais très courts.",
              "Nos offres sont proposées sous la forme d'abonnement afin de vous garantir une sérénité sur votre présence en ligne.",
              "Votre site internet tout compris sans que vous ayez besoin de faire quoi que ce soit, nous nous occupons de tout pendant que vous poursuivez votre activité sans vous soucier du web!"
            ]}
    />

    <PriceTable/>

    {/* <Checkout /> */}

    <QuadGrid/>

    <LeftContainer
      imageSrc="Saadify/undraw_Code_review_re_woeb.png"
      header="Prestation sur mesure"
      text = {[ "Vous ne trouvez pas un pack qui vous convient ? Nous vous proposons de réaliser une étude de votre besoin et de réaliser un site web sur mesure selon vos souhaits.",
                "Nous avons la possibilité de réaliser entre autres des sites internets sous WordPress ainsi que des sites de ventes e-commerce selon la solution de votre choix (Shopify, PrestaShop, WooCommerce, ...)",
               ]}
    />

  <RightContainer
      imageSrc="Saadify/undraw_online_ad_re_ol62.png"
      header="Présence en ligne"
      text = {[ "Afin de compléter nos services de créations web nous vous proposons également des prestations pour promouvoir votre présence en ligne.",
                "Que cela soit la gestion de vos e-mails, de vos annonces sur des plateformes spécialisées ou autres.",
               ]}
    />


    <Contact
      contactButton = {[
        "/contact",
        "Contact"
      ]}
    />
  </Layout>
)

export default DeveloppementWeb

export const pageQuery = graphql`
  query {
    hero: allImageSharp(filter: { original: { src: { regex: "/computer-1245714_1280/" } } }) {
      edges {
        node {
          id
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
